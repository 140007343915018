/*
 * Author:  Fedor Nikonov (fritylo)
 * Date:    2022-05-14, 6:10:20 PM
 */

import { PinkDown } from './img/Arrow/PinkDown';
import { PinkLeft } from './img/Arrow/PinkLeft';
import { PinkRight } from './img/Arrow/PinkRight';
import { Double } from './img/Circles/Double';
import { Triple } from './img/Circles/Triple';
import { Cosmetology } from './img/Shadow/Cosmetology';
import { CosmetologySky } from './img/Shadow/CosmetologySky';
import { LinearBackground } from './img/Shadow/LinearBackground';
import { RealCosmetology } from './img/Shadow/RealCosmetology';

export const Svg = {
   Shadow: {
      Cosmetology,
      CosmetologySky,
      RealCosmetology,
      LinearBackground,
   },
   Arrow: {
      PinkLeft,
      PinkRight,
      PinkDown,
   },
   Circles: {
      Triple,
      Double,
   }
}

function importerForVsCode() {
   return (
      <div>
      </div>
   )
}