/*
 * Author:  Fedor Nikonov (fritylo)
 * Date:    2022-05-16, 2:47:34 AM
 */
   
import styles from './SectionFooter.module.scss';
import { classNameResolver } from 'react-class-name-resolver';
import { Logo } from '../../components/Logo/Logo';
import { Title } from '../../components/Title/Title';
import { Adaptive } from '../../components/Adaptive/Adaptive';
import { GetAppLinks } from '../GetAppLinks/GetAppLinks';
import { GetAgreeLink, GetConfidentLink } from '../GetConfidentLink/GetConfidentLink';

const cn = classNameResolver(styles);

export function SectionFooter({
   className,
}) {
   return (
      <Adaptive className={cn`section-footer section` + className}>
         <div className={cn`section__content`}>
            <Logo className={cn`section__logo`} />
            <img
               className={cn`section__presentation-mobile`}
               src='./images/screens/footer-phone-mobile.png'
               alt='RC Cosmetology в действии' />
            <Title className={cn`section__title`}>Теперь косметология доступна дома!</Title>
            <Title className={cn`section__subtitle`}>Профессиональный косметолог в вашем телефоне</Title>
            <GetAppLinks className={cn`section__get-app-links`}/>
            <GetConfidentLink className={cn`section__get-confident-link`}/>
            <GetAgreeLink className={cn`section__get-agree-link`} />
         </div>
         <img
            className={cn`section__presentation`}
            src='./images/screens/footer-phones.png'
            alt='RC Cosmetology в действии' />
      </Adaptive>
   )
}