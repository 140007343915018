/*
 * Author:  Fedor Nikonov (fritylo)
 * Date:    2022-05-08, 6:00:20 PM
 */

import { Button } from '../../components/Button/Button';
import { Adaptive } from '../../components/Adaptive/Adaptive';
import { Logo } from '../../components/Logo/Logo';
import { Title } from '../../components/Title/Title';
import { GetAppLinks } from '../GetAppLinks/GetAppLinks';
import { Image } from '../../components/Image/Image';
import { Blink } from '../../components/Blink/Blink';
import { Svg } from '../../components/Svg/Svg';

import styles from './SectionCare.module.scss';
import { classNameResolver } from 'react-class-name-resolver';
const cn = classNameResolver(styles);

export function SectionCare() {
  return (
    <div className={cn`section-care`}>
      <div className={cn`background`}>
        <Svg.Shadow.LinearBackground />
      </div>

      <Adaptive className={cn`content-wrapper`}>
        <div className={cn`header`}>
          <Logo className={cn`header__logo`} />
          <a
            href="mailto:hello@rccosmetology.com"
            target="_blank"
            rel="noreferrer"
            className={cn`header-button`}
          >
            <Button
              className={cn`header__button`}
              text="hello@rccosmetology.com"
            />
          </a>
        </div>
        <div className={cn`content`}>
          <div className={cn`text`}>
            <Title className={cn`title`}>
              Профессиональный уход за собой в домашних условиях
            </Title>
            <p className={cn`paragraph`}>
              <strong>RC Cosmetology</strong> - это уникальное приложение,
              которое анализирует особенности состояния вашего лица и дает
              конкретные рекомендации по его преображению
            </p>
            <GetAppLinks className={cn`get-app-links get-app-links_pc`} />
          </div>
          <div className={cn`presentation`}>
            <div className={cn`presentation__frame`}>
              <Image
                aos={{ type: 'fade-right', delay: '0' }}
                src="screens/loading.png"
                alt="Loading screen"
              />
            </div>
            <div className={cn`presentation__frame`}>
              <Image
                aos={{ type: 'fade-right', delay: '200' }}
                src="screens/cosmo-pass.png"
                alt="Cosmo passport"
              />
            </div>
            <div className={cn`presentation__frame`}>
              <Image
                aos={{ type: 'fade-right', delay: '400' }}
                src="screens/profile.png"
                alt="User profile"
              />
            </div>
            <div className={cn`glare`}>
              <Blink />
              <Blink />
              <Blink />
            </div>
          </div>
          <GetAppLinks className={cn`get-app-links get-app-links_mobile`} />
          <a
            href="mailto:rccosmetology@gmail.com"
            target="_blank"
            rel="noreferrer"
            className={cn`email-mobile`}
          >
            <Button
              className={cn`email-mobile__button`}
              text="rccosmetology@gmail.com"
            />
          </a>
        </div>
      </Adaptive>

      <div className={cn`dots`}>
        <div className={cn`dot dot-t-c dot_animation_lb-rt`}></div>
        <div className={cn`dot dot-t-cr dot_animation_lb-rt`}></div>
        <div className={cn`dot dot-t-crr dot_animation_lb-rt`}></div>
        <div className={cn`dot dot-t-r dot_animation_lt-rb`}></div>
        <div className={cn`dot dot-t-rr dot_animation_lt-rb`}></div>
        <div className={cn`dot dot-b-lll dot_animation_lt-rb`}></div>
        <div className={cn`dot dot-b-ll dot_animation_lt-rb`}></div>
        <div className={cn`dot dot-b-l dot_animation_lt-rb`}></div>
      </div>
    </div>
  );
}
