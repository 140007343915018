export function Triple({ className }) {
   return (
      <svg className={className} width="947" height="949" viewBox="0 0 947 949" fill="none" xmlns="http://www.w3.org/2000/svg">
         <ellipse cx="300.184" cy="297" rx="300.184" ry="297" fill="url(#paint0_linear_5413_33)" />
         <ellipse cx="574.945" cy="550" rx="371.945" ry="368" fill="url(#paint1_linear_5413_33)" />
         <ellipse cx="304.184" cy="652" rx="300.184" ry="297" fill="url(#paint2_linear_5413_33)" />
         <defs>
            <linearGradient id="paint0_linear_5413_33" x1="300.184" y1="6.10352e-05" x2="300.184" y2="594" gradientUnits="userSpaceOnUse">
               <stop stopColor="#BCE1FE" stopOpacity="0.69" />
               <stop offset="1" stopColor="#BADFFD" stopOpacity="0" />
            </linearGradient>
            <linearGradient id="paint1_linear_5413_33" x1="574.945" y1="182" x2="574.945" y2="918" gradientUnits="userSpaceOnUse">
               <stop stopColor="#BCE1FE" stopOpacity="0.69" />
               <stop offset="1" stopColor="#BADFFD" stopOpacity="0" />
            </linearGradient>
            <linearGradient id="paint2_linear_5413_33" x1="304.184" y1="355" x2="304.184" y2="949" gradientUnits="userSpaceOnUse">
               <stop stopColor="#BCE1FE" stopOpacity="0.69" />
               <stop offset="1" stopColor="#BADFFD" stopOpacity="0" />
            </linearGradient>
         </defs>
      </svg>
   )
}