/*
 * Author:  Fedor Nikonov (fritylo)
 * Date:    2022-05-09, 12:00:43 AM
 */
   
import RustoreSvg from '../../features/RustoreSvg/RustoreSvg';
import styles from './GetAppLinks.module.scss';
import { classNameResolver } from 'react-class-name-resolver';
const cn = classNameResolver(styles);

export function GetAppLinks({
   className,
}) {

   return (
      <>
         <div className={cn`get-app-links` + className}>
            <a target='_blank' href="https://apps.apple.com/ru/app/rc-cosmetology/id1665458339">
               <img src="./images/app-store.png" alt="Download on the App Store" />
            </a>
            <a target='_blank' href="https://play.google.com/store/apps/details?id=com.realcosmetology.android&hl=ru&gl=US">
               <img src="./images/google-play.png" alt="Get it on Google Play" />
            </a>
            <a target='_blank' href="https://apps.rustore.ru/app/com.realcosmetology.android">
               <RustoreSvg />
            </a>
         </div>
      </>
   )
}