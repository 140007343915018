/*
 * Author:  Fedor Nikonov (fritylo)
 * Date:    2022-05-14, 6:07:36 PM
 */
   
import styles from './SectionsMirrored.module.scss';
import { classNameResolver } from 'react-class-name-resolver';
import { Svg } from '../../components/Svg/Svg';
import { Title } from '../../components/Title/Title';
import { Blink } from '../../components/Blink/Blink';
import { Adaptive } from '../../components/Adaptive/Adaptive';
const cn = classNameResolver(styles);

export function SectionsMirrored() {
   return (
      <div className={cn`sections-mirrored`}>
         <Adaptive className={cn`section section_pass`}>
            <div className={cn`section__shadow`}>
               <Svg.Shadow.Cosmetology />
            </div>
            <Title className={cn`section__title_mobile`}>Космо-паспорт</Title>
            <div className={cn`section__presentation`}>
               <Svg.Circles.Triple className={cn`section__triple`} />
               <div className={cn`section_pass__gen-review-wrapper`}>
                  <img className={cn`section_pass__gen-review`} src='./images/screens/review2.png' alt='Генерация отчета' />
                  <img className={cn`section_pass__age-group`} src='./images/screens/age-group.png' alt='Возрастная группа' data-aos="fade-left" />
                  <Blink className={cn`section_pass__blink`} />
                  <Blink className={cn`section_pass__blink-small_reviews`} />
               </div>
               <div className={cn`section_pass__phototype-wrapper`}>
                  <img className={cn`section_pass__phototype`} src='./images/screens/phototype.png' alt='Определение фототипа кожи' />
                  <img className={cn`section_pass__phototype-rccab`} src='./images/screens/rc_cab_w_logo.png' alt='КЯабинет косметологии' />
                  <img className={cn`section_pass__phototype-woman`} src='./images/screens/womn.png' alt='Маска для лица' data-aos="fade-right" data-aos-delay="100" />
                  <img className={cn`section_pass__phototype-redhead`} src='./images/screens/redhair_girl.png' alt='Рыжая девушка' />
                  <Blink className={cn`section_pass__blink-small_phototype`} />
               </div>
            </div>
            <div className={cn`section__content`}>
               <Title className={cn`section__title`}>Космо-паспорт</Title>
               <p>
                  Заполните Космо-паспорт:
                  пройдите диагностику состояния вашего лица.
                  Для точности результатов сравнивайте ваши особенности с детальными фотографиями
               </p>
               <p>
                  Подробные описания  не дадут вам запутаться и сделать неточный выбор
               </p>
            </div>
         </Adaptive>

         <Adaptive className={cn`section section_reviews`}>
            <div className={cn`section__content`}>
               <Title className={cn`section__title`}>Кастомизированные отчеты</Title>
               <p>
                  Уникальный алгоритм проанализирует все 38 параметров вашей
                  кожи и составит подробный отчет с 3D проекцией
               </p>
               <p>
                  Узнайте, как ухаживать за вашей кожей лица на основе полученных данных
                  и получите конкретные рекомендации от профессионального косметолога
               </p>
            </div>
            <div className={cn`section__presentation`}>
               <Svg.Circles.Double className={cn`section__double`} />
               <div className={cn`section_reviews__cosmo-pass-wrapper`}>
                  <img className={cn`section_reviews__cosmo-pass`} src='./images/screens/cosmo-pass.png' alt='Космо-паспорт' />
                  <img className={cn`section_reviews__lets-talk`} src='./images/screens/lets-talk.png' alt='Давайте расскажу более подробно' data-aos="fade-left" />
                  <img className={cn`section_reviews__mimic-wrinkle`} src='./images/screens/mimic-wrinkle.png' alt='Мимические морщины' data-aos="fade-right" data-aos-delay='500' />
                  <Blink className={cn`section_reviews__blink-small_cosmo-pass`} />
               </div>
               <div className={cn`section_reviews__schema-wrapper`}>
                  <img className={cn`section_reviews__schema`} src='./images/screens/schema.png' alt='Схема лица' />
                  <img className={cn`section_reviews__circle-derma`} src='./images/screens/circle-derma.png' alt='Дерма' data-aos="zoom-out-left" data-aos-delay="200" />
                  <img className={cn`section_reviews__circle-eye`} src='./images/screens/circle-eye.png' alt='Глаз' data-aos="zoom-out-right" data-aos-delay="300" />
                  <Blink className={cn`section_reviews__blink`} />
                  <Blink className={cn`section_reviews__blink-small_schema`} />
               </div>
            </div>
            <Title className={cn`section__title_mobile`}>Кастомизированные отчеты</Title>
            <div className={cn`section__shadow`}>
               <Svg.Shadow.RealCosmetology />
            </div>
         </Adaptive>
      </div>
   )
}