import React, { useEffect } from 'react';

import AOS from 'aos';
import "aos/dist/aos.css";

import { SectionCare } from './blocks/SectionCare/SectionCare';
import { SectionsMirrored } from './blocks/SectionsMirrored/SectionsMirrored';
import { SectionAccounting } from './blocks/SectionAccounting/SectionAccounting';
import { BackgroundWave } from './blocks/BackgroundWave/BackgroundWave';
import { SectionScreens } from './blocks/SectionScreens/SectionScreens';
import { SectionPrograms } from './blocks/SectionPrograms/SectionPrograms';
import { SectionFooter } from './blocks/SectionFooter/SectionFooter';
import SectionArticles from "./blocks/SectionArticles/SectionArticles";


function App() {
   useEffect(() => {
      AOS.init();
      AOS.refresh();
   }, []);

   return (
      <div className="App">
         <SectionCare />
         <SectionsMirrored />
         <BackgroundWave>
            <SectionAccounting />
            <SectionScreens />
            <SectionArticles />
            <SectionPrograms />
         </BackgroundWave>
         <SectionFooter />
      </div>
   );
}

export default App;
