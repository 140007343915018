import styles from "./GetConfidentLink.scss";
import { classNameResolver } from "react-class-name-resolver";
import { Link } from "react-router-dom";

const cn = classNameResolver(styles);

export function GetConfidentLink({ className }) {
  return (
    <div className={cn`get-confident-link` + className}>
      <a
        href="https://drive.google.com/file/d/1cUvzeI-H0GFDGv9wAlhrFRd3gwHAKndT/view?usp=sharing"
        target="_blank"
        style={{ color: "black" }}
      >
        Политика <br /> конфиденциальности
      </a>
    </div>
  );
}

export function GetAgreeLink({ className }) {
  return (
    <div className={cn`get-agree-link` + className}>
      <a
        href="https://drive.google.com/file/d/1sEFwDuHe9NPXQQlmYum591Dvu79OqGGD/view?usp=sharing"
        target="_blank"
        style={{ color: "black" }}
      >
        Лицензионное <br /> соглашение
      </a>
    </div>
  );
}
