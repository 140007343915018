export function Double({ className }) {
   return (
      <svg className={className} width="1061" height="919" viewBox="0 0 1061 919" fill="none" xmlns="http://www.w3.org/2000/svg">
         <circle cx="649.5" cy="411.5" r="411.5" fill="url(#paint0_linear_5413_34)" />
         <circle cx="226" cy="693" r="226" fill="url(#paint1_linear_5413_34)" />
         <defs>
            <linearGradient id="paint0_linear_5413_34" x1="649.5" y1="0" x2="649.5" y2="823" gradientUnits="userSpaceOnUse">
               <stop stopColor="#AFDCFF" stopOpacity="0.69" />
               <stop offset="1" stopColor="#BADFFD" stopOpacity="0" />
            </linearGradient>
            <linearGradient id="paint1_linear_5413_34" x1="226" y1="467" x2="226" y2="919" gradientUnits="userSpaceOnUse">
               <stop stopColor="#BCE2FF" stopOpacity="0.69" />
               <stop offset="1" stopColor="#BADFFD" stopOpacity="0" />
            </linearGradient>
         </defs>
      </svg>
   )
}