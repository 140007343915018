/*
 * Author:  Fedor Nikonov (fritylo)
 * Date:    2022-05-15, 12:15:41 PM
 */
   
import styles from './BackgroundWave.module.scss';
import { classNameResolver } from 'react-class-name-resolver';
const cn = classNameResolver(styles);

export function BackgroundWave({
   className, children,
}) {
   return (
      <div className={cn`background-wave` + className}>
         {children}
      </div>
   )
}