import React from 'react';
import './LittleDotSvg.module.scss'

const LittleDotSvg = () => {
    return (
        <span className='svgg'>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10.0015" r="9.36735" fill="white" stroke="#FF97A2" stroke-width="0.734694"/>
            <circle cx="10" cy="10.0015" r="5" fill="#FF97A2"/>
        </svg>
        </span>

    );
};

export default LittleDotSvg;