export function LinearBackground() {
   return (
      <svg viewBox="0 0 1919 941" fill="none" xmlns="http://www.w3.org/2000/svg">
         <mask id="mask0_0_1" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="1923" height="941">
            <path d="M0 6.10352e-05H1923V828.067C1755.52 755.317 1650.38 721.616 1308.69 828.067C753.063 1001.17 378.976 954.309 0 828.067V6.10352e-05Z" fill="url(#paint0_linear_0_1)" />
         </mask>
         <g mask="url(#mask0_0_1)">
            <path d="M0 -4.99994H1948V1208H1256.35H0V-4.99994Z" fill="url(#paint1_linear_0_1)" />
         </g>
         <defs>
            <linearGradient id="paint0_linear_0_1" x1="22.0051" y1="47.8306" x2="1753.96" y2="1087.69" gradientUnits="userSpaceOnUse">
               <stop stopColor="#F2F7FD" />
               <stop offset="0.477725" stopColor="#BEE4FF" />
               <stop offset="0.998153" stopColor="#7990DD" />
            </linearGradient>
            <linearGradient id="paint1_linear_0_1" x1="-101" y1="35.0001" x2="2192.31" y2="428.435" gradientUnits="userSpaceOnUse">
               <stop stopColor="#F2F7FD" />
               <stop offset="0.422272" stopColor="#BEE4FF" />
               <stop offset="0.998153" stopColor="#7990DD" />
            </linearGradient>
         </defs>
      </svg>
   )
}