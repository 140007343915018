/*
 * Author:  Fedor Nikonov (fritylo)
 * Date:    2022-05-08, 3:01:57 PM
 * Company: frity corp.
 */
   
import styles from './Button.module.scss';
import { classNameResolver } from 'react-class-name-resolver';
const cn = classNameResolver(styles);

export function Button({
   text, className,
}) {
   return (
      <button className={cn`button` + className}>
         {text}
      </button>
   )
}