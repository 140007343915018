/*
 * Author:  Fedor Nikonov (fritylo)
 * Date:    2022-05-08, 11:50:55 PM
 */
   
import styles from './Title.module.scss';
import { classNameResolver } from 'react-class-name-resolver';
const cn = classNameResolver(styles);

export function Title({
   children, className,
}) {
   return (
      <div className={cn`title` + className}>
         {children}
      </div>
   )
}