/*
 * Author:  Fedor Nikonov (fritylo)
 * Date:    2022-05-15, 11:27:58 AM
 */
   
import styles from './SectionAccounting.module.scss';
import { classNameResolver } from 'react-class-name-resolver';
import { Title } from '../../components/Title/Title';
import { Blink } from '../../components/Blink/Blink';
import { Adaptive } from '../../components/Adaptive/Adaptive';
const cn = classNameResolver(styles);

export function SectionAccounting({
   className,
}) {
   return (
      <Adaptive className={cn`section-accounting section` + className}>
         <div className={cn`section__presentation`}>
            <div className={cn`section__procedures-wrapper`}>
               <img
                  className={cn`section__procedures`}
                  src='./images/screens/procedures.png'
                  alt='Учет процедур' />
               <img
                  className={cn`section__save-your-procedures`} 
                  src='./images/screens/save-your-procedures.png' 
                  alt='Сохраняйте свои процедуры'
                  data-aos='fade-left' />
               <Blink className={cn`section__blink_large`} />
               <Blink className={cn`section__blink_medium`} />
            </div>
            <div className={cn`section__create-procedure-wrapper`}>
               <img
                  className={cn`section__create-procedure`}
                  src='./images/screens/create-procedure.png'
                  alt='Создать процедуру' />
               <img
                  className={cn`section__15-minutes`} 
                  src='./images/screens/15-minutes.png' 
                  alt='За 15 минут'
                  data-aos='fade-right' 
                  data-aos-delay='100' />
               <Blink className={cn`section__blink_small`} />
            </div>
            <img
               className={cn`section__time-is-up`} 
               src='./images/screens/time-is-up.png' 
               alt='Время истекло'
               data-aos='fade-down'
               data-aos-delay='200' />
            <div className={cn`section__shadow`}></div>
         </div>
      
         <div className={cn`section__content`}>
            <Title className={cn`section__title`}>Ведите учет процедур</Title>
            <p>
               В приложении абсолютно бесплатно вы можете планировать процедуры и отмечать выполненные
            </p>

            <div className={cn`section__feature feature`}>
               <div className={cn`feature__icon`}>
                  <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <circle cx="26.5" cy="26.5" r="25.5" fill="white" stroke="#FF97A2" strokeWidth="2" />
                     <path d="M40.5415 35.0302C40.5415 35.6942 40.2777 36.3311 39.8082 36.8006C39.3386 37.2702 38.7018 37.534 38.0377 37.534H15.5038C14.8397 37.534 14.2029 37.2702 13.7333 36.8006C13.2638 36.3311 13 35.6942 13 35.0302V21.2594C13 20.5954 13.2638 19.9585 13.7333 19.489C14.2029 19.0194 14.8397 18.7557 15.5038 18.7557H20.5113L23.0151 15H30.5264L33.0302 18.7557H38.0377C38.7018 18.7557 39.3386 19.0194 39.8082 19.489C40.2777 19.9585 40.5415 20.5954 40.5415 21.2594V35.0302Z" stroke="#FF97A2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                     <path d="M26.7708 32.5264C29.5363 32.5264 31.7783 30.2845 31.7783 27.5189C31.7783 24.7533 29.5363 22.5113 26.7708 22.5113C24.0052 22.5113 21.7632 24.7533 21.7632 27.5189C21.7632 30.2845 24.0052 32.5264 26.7708 32.5264Z" stroke="#FF97A2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
               </div>
               <div className={cn`feature__title`}>Прикрепляйте фотографии “До/После”</div>
            </div>

            <div className={cn`section__feature feature`}>
               <div className={cn`feature__icon`}>
                  <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <circle cx="26.5" cy="26.5" r="25.5" fill="white" stroke="#FF97A2" strokeWidth="2" />
                     <path d="M26 37.6667H38" stroke="#FF97A2" strokeWidth="2.66667" strokeLinecap="round" strokeLinejoin="round" />
                     <path d="M32 15.6666C32.5304 15.1361 33.2499 14.8381 34 14.8381C34.3714 14.8381 34.7392 14.9113 35.0824 15.0534C35.4256 15.1956 35.7374 15.4039 36 15.6666C36.2626 15.9292 36.471 16.241 36.6131 16.5842C36.7553 16.9273 36.8284 17.2951 36.8284 17.6666C36.8284 18.038 36.7553 18.4058 36.6131 18.749C36.471 19.0921 36.2626 19.4039 36 19.6666L19.3333 36.3332L14 37.6666L15.3333 32.3332L32 15.6666Z" stroke="#FF97A2" strokeWidth="2.2069" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
               </div>
               <div className={cn`feature__title`}>Оставляйте комментарии к процедуре</div>
            </div>
         </div>
      </Adaptive>
   )
}