/*
 * Author:  Fedor Nikonov (fritylo)
 * Date:    2022-05-08, 5:06:54 PM
 * Company: frity corp.
 */
   
import styles from './Adaptive.module.scss';
import { classNameResolver } from 'react-class-name-resolver';
const cn = classNameResolver(styles);

export function Adaptive({
   children,
   className,
}) {
   return (
      <div className={cn`adaptive` + className}>
         {children}
      </div>
   )
}