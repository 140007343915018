/*
 * Author:  Fedor Nikonov (fritylo)
 * Date:    2022-05-16, 12:31:50 AM
 */

import styles from './SectionPrograms.module.scss';
import { classNameResolver } from 'react-class-name-resolver';
import { Title } from '../../components/Title/Title';
import { Button } from '../../components/Button/Button';
import { Blink } from '../../components/Blink/Blink';
import { Adaptive } from '../../components/Adaptive/Adaptive';
const cn = classNameResolver(styles);

export function SectionPrograms({
   className,
}) {
   return (
      <Adaptive className={cn`section-programs section` + className}>
         <Title className={cn`section__title`}>Авторские программы</Title>

         <p className={cn`section__text`}>
            Для лифтинга, сохранения молодости и преображения лица
            для вас подготовлены персональные рекомендации
         </p>

         <div className={cn`section__buttons`}>
            <div className={cn`section__button-wrapper`}>
               <Blink className={cn`section__button-blink`} />
               <Button className={cn`section__button`} text='Программы ежедневного ухода' />
            </div>
            <div className={cn`section__button-wrapper`}>
               <Blink className={cn`section__button-blink`} />
               <Button className={cn`section__button`} text='Программы процедур в домашних условиях' />
            </div>
            <div className={cn`section__button-wrapper`}>
               <Blink className={cn`section__button-blink`} />
               <Button className={cn`section__button`} text='Мастер-классы' />
            </div>
            <div className={cn`section__button-wrapper`}>
               <Blink className={cn`section__button-blink`} />
               <Button className={cn`section__button`} text='Профессиональные процедуры' />
            </div>
         </div>

         <div className={cn`section__presentation`}>
            <img
               className={cn`section__collage`}
               src='./images/screens/collage-mega.png'
               alt='Коллаж экранов' />
            <img
               className={cn`section__collage-mobile`}
               src='./images/screens/collage-mobile.png'
               alt='Коллаж экранов' />
            <img
               className={cn`section__face`}
               src='./images/screens/face-marks.png'
               alt='Схема лица' />
         </div>
      </Adaptive>
   )
}
