/*
 * Author:  Fedor Nikonov (fritylo)
 * Date:    2022-05-15, 2:52:26 PM
 */
   
import styles from './SectionScreens.module.scss';
import { classNameResolver } from 'react-class-name-resolver';
import { Svg } from '../../components/Svg/Svg';
import { Adaptive } from '../../components/Adaptive/Adaptive';
const cn = classNameResolver(styles);

export function SectionScreens({
   className,
}) {
   return (
      <Adaptive className={cn`section-screens section` + className}>
         <div className={cn`section__downloads`}>
            <p>
               Выгружайте картинки и фотографии из Галереи
               на тему красоты и ухода за собой
               абсолютно бесплатно
            </p>
            <div className={cn`section__downloads-presentation`}>
               <img
                  className={cn`section__download-image`}
                  src='./images/screens/download-image.png'
                  alt='Скачать изображение'
                  data-aos="fade-down-left"
                  data-aos-anchor-placement="bottom-bottom"
                  data-aos-delay='700' />
               <img
                  className={cn`section__gallery`}
                  src='./images/screens/gallery.png'
                  alt='Галерея'
                  data-aos="fade-down-left"
                  data-aos-anchor-placement="bottom-bottom"
                  data-aos-delay='800' />
               <img
                  className={cn`section__smile-soap`}
                  src='./images/screens/smile-soap.png'
                  alt='Улыбчивая девушка'
                  data-aos="fade-down-left"
                  data-aos-anchor-placement="bottom-bottom"
                  data-aos-delay='900' />
            </div>
         </div>

         <div className={cn`section__main`}>
            <img
               className={cn`section__main-page`}
               src='./images/screens/main_page_new.png'
               alt='Главная страница' />
            <img
               className={cn`section__open-season`}
               src='./images/screens/remove_for_1month.png'
               alt='Устранить высыпания за 1 месяц'
               data-aos='fade-right'
               data-aos-anchor-placement="bottom-bottom"
               data-aos-delay='0' />
            <img
               className={cn`section__social-gallery`}
               src='./images/screens/social-gallery.png'
               alt='Галерея для соцсетей'
               data-aos='fade-left' />
      
            <Svg.Arrow.PinkLeft className={cn`section__arrow-to-downloads`} data-aos='fade-left' data-aos-delay='100' />
            <Svg.Arrow.PinkRight className={cn`section__arrow-to-news`} data-aos='fade-right' data-aos-delay='400' />
            <Svg.Arrow.PinkDown className={cn`section__arrow-mobile`} />
         </div>

         <div className={cn`section__news`}>
            <div className={cn`section__news-content`}>
               <p>
                  Читайте лучшие новости
                  из мира красоты
               </p>
               <div className={cn`section__news-presentation`}>
                  <img
                     className={cn`section__news-screen`}
                     src='./images/screens/news1.png'
                     alt='Экран новостей'
                     data-aos="fade-down-right"
                     data-aos-anchor-placement="bottom-bottom"
                     data-aos-delay='1400' />
                  <img
                     className={cn`section__skin-care`}
                     src='./images/screens/cosmo_review.png'
                     alt='Уход за кожей'
                     data-aos="fade-down-right"
                     data-aos-anchor-placement="bottom-bottom"
                     data-aos-delay='1500' />
               </div>
            </div>
         </div>

         <Svg.Shadow.CosmetologySky className={cn`section__shadow`} />
      </Adaptive>
   )
}