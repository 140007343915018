/*
 * Author:  Fedor Nikonov (fritylo)
 * Date:    2022-05-14, 12:33:40 PM
 */
   
import styles from './Image.module.scss';
import { classNameResolver } from 'react-class-name-resolver';
const cn = classNameResolver(styles);

export function Image({
   src,
   alt,
   aos,
}) {
   return (
      <div className={cn`image`} {...(aos !== undefined ? {'data-aos': aos.type, 'data-aos-delay': aos.delay} : {})}>
         <img src={`./images/${src}`} alt={alt} />
      </div>
   )
}