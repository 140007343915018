import React from 'react';
import styles from './SectionArticles.module.scss';
import {classNameResolver} from 'react-class-name-resolver';
import {Svg} from '../../components/Svg/Svg';
import {Adaptive} from '../../components/Adaptive/Adaptive';
import {Title} from "../../components/Title/Title";
import {Blink} from "../../components/Blink/Blink";
import LittleDotSvg from "../../features/LittleDotSvg/LittleDotSvg";

const cn = classNameResolver(styles);


const SectionArticles = ({className}) => {
    return (
        <div className={cn`sections-articles`}>
            <Adaptive className={cn`section section_reviews`}>

                <div className={cn`section__content`}>
                    <Title className={cn`section__title`}>Персональные консультации в виде статей</Title>
                    <ul>

                        <li>
                            <LittleDotSvg/>
                            <p>
                                Исчерпывающая информация о проблеме от профессионального косметолога </p>
                        </li>
                        <li>
                            <LittleDotSvg/>
                            <p>
                                Разбор причин возникновения
                            </p>

                        </li>

                        <li>
                            <LittleDotSvg/>
                            <p>
                            Ответы на вопросы: о том, что вы можете предпринять самостоятельно в ежедневном уходе
                        </p>
                        </li>

                        <li>
                            <LittleDotSvg/>
                           <p>
                            Обзор домашних и профессиональных процедур для решения проблем
                           </p></li>
                    </ul>
                </div>


                <div className={cn`section__presentation`}>
                    <div className={cn`section_article__articlaFirst-wrapper`}>
                        <img
                            className={cn`section_reviews__articlaFirst`}
                            src='./images/screens/article1.png'
                            alt='статья'/>
                        <Blink className={cn`section__blink_medium`}/>
                        <Blink className={cn`section__blink_small`}/>
                    </div>
                    <div className={cn`section_reviews__articlaSecond-wrapper`}>
                        <img
                            className={cn`section_reviews__articlaSecond`}
                            src='./images/screens/article2.png'
                            alt='статья'/>
                        <Blink className={cn`section__blink_last`}/>
                    </div>
                </div>

            </Adaptive>
        </div>
    );
};

export default SectionArticles;


// <ul className={cn`section__unorderedList`} >
//
//     <li>
//         <p>
//             Исчерпывающая информация о проблеме от профессионального косметолога</p></li>
//
//     <li>
//         Разбор причин возникновения</li>
//
//     <li>
//         Ответы на вопросы: о том, что вы можете предпринять самостоятельно в ежедневном уходе</li>
//
//     <li>
//         Обзор домашних и профессиональных процедур для решения проблем</li>
// </ul>
