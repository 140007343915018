/*
 * Author:  Fedor Nikonov (fritylo)
 * Date:    2022-05-08, 1:33:50 PM
 * Company: JoyDev
 */
   
import styles from './Blink.module.scss';
import { classNameResolver } from 'react-class-name-resolver';
const cn = classNameResolver(styles);

export function Blink({
   className,
}) {
   return (
      <div className={cn`blink` + className}>
         <svg width="350" height="322" viewBox="0 0 350 322" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="url(#paint0_radial_121_63)" className={cn`glow`} d="M175.004 241.169C223.526 241.169 262.861 205.079 262.861 160.561C262.861 116.042 223.526 79.953 175.004 79.953C126.481 79.953 87.1465 116.042 87.1465 160.561C87.1465 205.079 126.481 241.169 175.004 241.169Z" />
            <path fill="url(#paint1_radial_121_63)" className={cn`ray ray_large_horiz`} d="M175 175.766C271.65 175.766 350 168.959 350 160.562C350 152.165 271.65 145.358 175 145.358C78.3502 145.358 0 152.165 0 160.562C0 168.959 78.3502 175.766 175 175.766Z" />
            <path fill="url(#paint8_radial_121_63)" className={cn`ray ray_large_vert`} d="M174.997 321.121C184.149 321.121 191.569 249.235 191.569 160.56C191.569 71.8853 184.149 6.10352e-05 174.997 6.10352e-05C165.845 6.10352e-05 158.426 71.8853 158.426 160.56C158.426 249.235 165.845 321.121 174.997 321.121Z" />
            <path fill="url(#paint2_radial_121_63)" className={cn`ray ray_medium_rt-lb`} d="M298.702 47.0539C300.131 48.3646 245.988 100.399 177.559 163.051C109.131 225.833 52.5592 275.508 51.1307 274.198C49.7021 272.887 103.845 220.852 172.274 158.201C240.702 95.5497 297.274 45.7432 298.702 47.0539Z" />
            <path fill="url(#paint3_radial_121_63)" className={cn`ray ray_medium_lt-rb`} d="M51.1306 47.0539C52.5592 45.7432 109.273 95.4187 177.559 158.201C245.845 220.984 300.131 272.887 298.702 274.198C297.273 275.509 240.559 225.833 172.273 163.051C103.988 100.268 49.702 48.3646 51.1306 47.0539Z" />
            <path fill="url(#paint4_radial_121_63)" className={cn`ray ray_small_rrt-llb`} d="M278.43 119.798C279.001 120.977 233.144 140.113 176.001 162.657C118.858 185.201 72.1438 202.503 71.7152 201.454C71.1438 200.274 117.001 181.138 174.144 158.594C231.144 136.05 277.858 118.618 278.43 119.798Z" />
            <path fill="url(#paint5_radial_121_63)" className={cn`ray ray_small_ltt-rbb`} d="M130.573 65.7986C131.858 65.2743 152.716 107.348 177.287 159.775C201.858 212.203 220.716 255.063 219.573 255.456C218.287 255.98 197.43 213.907 172.858 161.479C148.144 109.052 129.287 66.1918 130.573 65.7986Z" />
            <path fill="url(#paint6_radial_121_63)" className={cn`ray ray_extra_rrt-llb`} d="M242.719 185.331C242.434 186.118 211.862 175.632 174.434 161.87C137.005 148.239 106.862 136.442 107.291 135.787C107.576 135.001 138.148 145.486 175.576 159.248C213.005 172.88 243.148 184.676 242.719 185.331Z" />
            <path fill="url(#paint7_radial_121_63)" className={cn`ray ray_extra_rtt_lbb`} d="M202.005 98.4321C202.862 98.6942 191.434 126.743 176.434 161.083C161.577 195.424 148.719 223.079 148.005 222.686C147.148 222.424 158.577 194.375 173.577 160.035C188.434 125.695 201.148 98.17 202.005 98.4321Z" />
            <path fill="url(#paint9_radial_121_63)" className={cn`star`} d="M208.137 122.289L209.28 141.425L230.137 142.474L209.28 143.391L208.137 162.527L207.137 143.391L186.28 142.474L207.137 141.425L208.137 122.289Z" />
            <path fill="url(#paint10_radial_121_63)" className={cn`dot`} d="M167.855 149.55V150.468H168.855H167.855V151.385V150.468H166.855H167.855V149.55Z" />
            <path fill="url(#paint11_radial_121_63)" className={cn`dot`} d="M169.999 133.692V134.478L170.999 134.609H169.999V135.395V134.609H168.999L169.999 134.478V133.692Z" />
            <path fill="url(#paint12_radial_121_63)" className={cn`dot`} d="M178 129.497V130.284L179 130.415H178V131.201V130.415H177L178 130.284V129.497Z" />
            <path fill="url(#paint13_radial_121_63)" className={cn`dot`} d="M174.002 145.095V145.882H175.002L174.002 146.013V146.799V146.013L173.002 145.882H174.002V145.095Z" />
            <path fill="url(#paint14_radial_121_63)" className={cn`dot`} d="M178.996 138.018V138.804H179.996L178.996 138.935V139.722L178.853 138.935L177.996 138.804H178.853L178.996 138.018Z" />
            <path fill="url(#paint15_radial_121_63)" className={cn`dot`} d="M162.285 175.896L162.427 174.716L163.713 174.585H162.427L162.285 173.274V174.585H160.856L162.285 174.716V175.896Z" />
            <path fill="url(#paint16_radial_121_63)" className={cn`dot`} d="M164.144 172.62V171.44L165.572 171.309H164.144V169.998V171.309H162.715L164.144 171.44V172.62Z" />
            <path fill="url(#paint17_radial_121_63)" className={cn`dot`} d="M167.713 177.207L167.856 175.896H169.141L167.856 175.765L167.713 174.585V175.765L166.284 175.896H167.713V177.207Z" />
            <path fill="url(#paint18_radial_121_63)" className={cn`dot`} d="M184.716 185.202L184.859 184.022L186.144 183.891H184.859L184.716 182.712V183.891H183.287L184.716 184.022V185.202Z" />
            <path fill="url(#paint19_radial_121_63)" className={cn`dot`} d="M167.713 191.757L167.856 190.577L169.141 190.446H167.856L167.713 189.267V190.446H166.284L167.713 190.577V191.757Z" />
            <path fill="url(#paint20_radial_121_63)" className={cn`dot`} d="M190.718 174.324V173.144L192.146 173.013H190.718V171.833V173.013H189.289L190.718 173.144V174.324Z" />
            <path fill="url(#paint21_radial_121_63)" className={cn`dot`} d="M169.854 173.013V172.358H170.426H169.854V171.703L169.712 172.358H169.14H169.712L169.854 173.013Z" />
            <path fill="url(#paint22_radial_121_63)" className={cn`dot`} d="M171.285 183.891V183.367H171.999H171.285V182.712V183.367H170.571H171.285V183.891Z" />
            <path fill="url(#paint23_radial_121_63)" className={cn`dot`} d="M176.715 186.907L176.858 186.252H177.43H176.858L176.715 185.597V186.252H176.144H176.715V186.907Z" />
            <path fill="url(#paint24_radial_121_63)" className={cn`dot`} d="M173.999 176.16V175.504H174.713H173.999V174.849V175.504H173.427H173.999V176.16Z" />
            <path fill="url(#paint25_radial_121_63)" className={cn`dot`} d="M177.426 181.009V180.485L178.14 180.354H177.426V179.829V180.354H176.712L177.426 180.485V181.009Z" />
            <defs>
               <radialGradient id="paint0_radial_121_63" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(174.992 160.153) scale(68.0873 64.0684)">
                  <stop stopColor={'white'} />
                  <stop offset="0.0239512" stopColor={'white'} stopOpacity={0.976} />
                  <stop offset="0.1385" stopColor={'white'} stopOpacity={0.8615} />
                  <stop offset="0.2586" stopColor={'white'} stopOpacity={0.7414} />
                  <stop offset="0.3831" stopColor={'white'} stopOpacity={0.6169} />
                  <stop offset="0.5135" stopColor={'white'} stopOpacity={0.4865} />
                  <stop offset="0.6522" stopColor={'white'} stopOpacity={0.3478} />
                  <stop offset="0.8052" stopColor={'white'} stopOpacity={0.1948} />
                  <stop offset="1" stopColor={'white'} stopOpacity={0} />
               </radialGradient>
               <radialGradient id="paint1_radial_121_63" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(174.988 160.515) scale(135.634 12.0985)">
                  <stop stopColor={'white'} />
                  <stop offset="0.0975655" stopColor={'white'} stopOpacity={0.9024} />
                  <stop offset="0.2033" stopColor={'white'} stopOpacity={0.7967} />
                  <stop offset="0.3144" stopColor={'white'} stopOpacity={0.6856} />
                  <stop offset="0.4295" stopColor={'white'} stopOpacity={0.5705} />
                  <stop offset="0.5501" stopColor={'white'} stopOpacity={0.4499} />
                  <stop offset="0.6784" stopColor={'white'} stopOpacity={0.3216} />
                  <stop offset="0.8199" stopColor={'white'} stopOpacity={0.1801} />
                  <stop offset="1" stopColor={'white'} stopOpacity={0} />
               </radialGradient>
               <radialGradient id="paint2_radial_121_63" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(174.961 160.586) rotate(-42.536) scale(130.202 2.85976)">
                  <stop stopColor={'white'} />
                  <stop offset="0.0466986" stopColor={'white'} stopOpacity={0.9533} />
                  <stop offset="0.1781" stopColor={'white'} stopOpacity={0.8219} />
                  <stop offset="0.316" stopColor={'white'} stopOpacity={0.684} />
                  <stop offset="0.4605" stopColor={'white'} stopOpacity={0.5395} />
                  <stop offset="0.6143" stopColor={'white'} stopOpacity={0.3857} />
                  <stop offset="0.784" stopColor={'white'} stopOpacity={0.216} />
                  <stop offset="1" stopColor={'white'} stopOpacity={0} />
               </radialGradient>
               <radialGradient id="paint3_radial_121_63" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(174.961 160.613) rotate(-137.464) scale(130.202 2.85976)">
                  <stop stopColor={'white'} />
                  <stop offset="0.0466986" stopColor={'white'} stopOpacity={0.9533} />
                  <stop offset="0.1781" stopColor={'white'} stopOpacity={0.8219} />
                  <stop offset="0.316" stopColor={'white'} stopOpacity={0.684} />
                  <stop offset="0.4605" stopColor={'white'} stopOpacity={0.5395} />
                  <stop offset="0.6143" stopColor={'white'} stopOpacity={0.3857} />
                  <stop offset="0.784" stopColor={'white'} stopOpacity={0.216} />
                  <stop offset="1" stopColor={'white'} stopOpacity={0} />
               </radialGradient>
               <radialGradient id="paint4_radial_121_63" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(174.984 160.588) rotate(-21.5402) scale(86.2212 1.78474)">
                  <stop stopColor={'white'} />
                  <stop offset="0.026269" stopColor={'#FFFCF4'} stopOpacity={0.9737} />
                  <stop offset="0.1404" stopColor={'white'} stopOpacity={0.8596} />
                  <stop offset="0.2603" stopColor={'white'} stopOpacity={0.7397} />
                  <stop offset="0.3845" stopColor={'white'} stopOpacity={0.6155} />
                  <stop offset="0.5146" stopColor={'white'} stopOpacity={0.4854} />
                  <stop offset="0.653" stopColor={'white'} stopOpacity={0.347} />
                  <stop offset="0.8056" stopColor={'white'} stopOpacity={0.1944} />
                  <stop offset="1" stopColor={'white'} stopOpacity={0} />
               </radialGradient>
               <radialGradient id="paint5_radial_121_63" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(174.977 160.606) rotate(-115.122) scale(81.2683 1.89343)">
                  <stop stopColor={'white'} />
                  <stop offset="0.026269" stopColor={'#FFFCF4'} stopOpacity={0.9737} />
                  <stop offset="0.1404" stopColor={'white'} stopOpacity={0.8596} />
                  <stop offset="0.2603" stopColor={'white'} stopOpacity={0.7397} />
                  <stop offset="0.3845" stopColor={'white'} stopOpacity={0.6155} />
                  <stop offset="0.5146" stopColor={'white'} stopOpacity={0.4854} />
                  <stop offset="0.653" stopColor={'white'} stopOpacity={0.347} />
                  <stop offset="0.8056" stopColor={'white'} stopOpacity={0.1944} />
                  <stop offset="1" stopColor={'white'} stopOpacity={0} />
               </radialGradient>
               <radialGradient id="paint6_radial_121_63" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(174.996 160.591) rotate(20.078) scale(55.8665 1.15738)">
                  <stop stopColor={'white'} />
                  <stop offset="0.026269" stopColor={'#FFFCF4'} stopOpacity={0.9737} />
                  <stop offset="0.1404" stopColor={'white'} stopOpacity={0.8596} />
                  <stop offset="0.2603" stopColor={'white'} stopOpacity={0.7397} />
                  <stop offset="0.3845" stopColor={'white'} stopOpacity={0.6155} />
                  <stop offset="0.5146" stopColor={'white'} stopOpacity={0.4854} />
                  <stop offset="0.653" stopColor={'white'} stopOpacity={0.347} />
                  <stop offset="0.8056" stopColor={'white'} stopOpacity={0.1944} />
                  <stop offset="1" stopColor={'white'} stopOpacity={0} />
               </radialGradient>
               <radialGradient id="paint7_radial_121_63" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(174.985 160.595) rotate(-66.5288) scale(52.4841 1.23197)">
                  <stop stopColor={'white'} />
                  <stop offset="0.026269" stopColor={'#FFFCF4'} stopOpacity={0.9737} />
                  <stop offset="0.1404" stopColor={'white'} stopOpacity={0.8596} />
                  <stop offset="0.2603" stopColor={'white'} stopOpacity={0.7397} />
                  <stop offset="0.3845" stopColor={'white'} stopOpacity={0.6155} />
                  <stop offset="0.5146" stopColor={'white'} stopOpacity={0.4854} />
                  <stop offset="0.653" stopColor={'white'} stopOpacity={0.347} />
                  <stop offset="0.8056" stopColor={'white'} stopOpacity={0.1944} />
                  <stop offset="1" stopColor={'white'} stopOpacity={0} />
               </radialGradient>
               <radialGradient id="paint8_radial_121_63" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(174.892 160.6) rotate(-90) scale(124.442 13.1866)">
                  <stop stopColor={'white'} />
                  <stop offset="0.0975655" stopColor={'white'} stopOpacity={0.9024} />
                  <stop offset="0.2033" stopColor={'white'} stopOpacity={0.7967} />
                  <stop offset="0.3144" stopColor={'white'} stopOpacity={0.6856} />
                  <stop offset="0.4295" stopColor={'white'} stopOpacity={0.5705} />
                  <stop offset="0.5501" stopColor={'white'} stopOpacity={0.4499} />
                  <stop offset="0.6784" stopColor={'white'} stopOpacity={0.3216} />
                  <stop offset="0.8199" stopColor={'white'} stopOpacity={0.1801} />
                  <stop offset="1" stopColor={'white'} stopOpacity={0} />
               </radialGradient>
               <radialGradient id="paint9_radial_121_63" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(208.76 142.625) rotate(0.0849062) scale(17.7252 16.643)">
                  <stop stopColor={'white'} />
                  <stop offset="0.0975655" stopColor={'white'} stopOpacity={0.9024} />
                  <stop offset="0.2033" stopColor={'white'} stopOpacity={0.7967} />
                  <stop offset="0.3144" stopColor={'white'} stopOpacity={0.6856} />
                  <stop offset="0.4295" stopColor={'white'} stopOpacity={0.5705} />
                  <stop offset="0.5501" stopColor={'white'} stopOpacity={0.4499} />
                  <stop offset="0.6784" stopColor={'white'} stopOpacity={0.3216} />
                  <stop offset="0.8199" stopColor={'white'} stopOpacity={0.1801} />
                  <stop offset="1" stopColor={'white'} stopOpacity={0} />
               </radialGradient>
               <radialGradient id="paint10_radial_121_63" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(167.897 150.494) rotate(0.0848985) scale(0.996863 0.935918)">
                  <stop stopColor={'#FFFFD3'} />
                  <stop offset="0.3456" stopColor={'#FFCE86'} stopOpacity={0.6544} />
                  <stop offset="0.7966" stopColor={'#FF9026'} stopOpacity={0.2034} />
                  <stop offset="1" stopColor={'#FF7800'} stopOpacity={0} />
               </radialGradient>
               <radialGradient id="paint11_radial_121_63" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(170.043 134.58) rotate(0.084895) scale(0.996867 0.935884)">
                  <stop stopColor={'#FFFFD3'} />
                  <stop offset="0.3456" stopColor={'#FFCE86'} stopOpacity={0.6544} />
                  <stop offset="0.7966" stopColor={'#FF9026'} stopOpacity={0.2034} />
                  <stop offset="1" stopColor={'#FF7800'} stopOpacity={0} />
               </radialGradient>
               <radialGradient id="paint12_radial_121_63" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(178.028 130.377) rotate(0.0848814) scale(0.996854 0.935722)">
                  <stop stopColor={'#FFFFD3'} />
                  <stop offset="0.3456" stopColor={'#FFCE86'} stopOpacity={0.6544} />
                  <stop offset="0.7966" stopColor={'#FF9026'} stopOpacity={0.2034} />
                  <stop offset="1" stopColor={'#FF7800'} stopOpacity={0} />
               </radialGradient>
               <radialGradient id="paint13_radial_121_63" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(174.051 145.926) rotate(0.0848957) scale(0.996858 0.935884)">
                  <stop stopColor={'#FFFFD3'} />
                  <stop offset="0.3456" stopColor={'#FFCE86'} stopOpacity={0.6544} />
                  <stop offset="0.7966" stopColor={'#FF9026'} stopOpacity={0.2034} />
                  <stop offset="1" stopColor={'#FF7800'} stopOpacity={0} />
               </radialGradient>
               <radialGradient id="paint14_radial_121_63" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(178.991 138.852) rotate(0.0849108) scale(0.996854 0.936046)">
                  <stop stopColor={'#FFFFD3'} />
                  <stop offset="0.3456" stopColor={'#FFCE86'} stopOpacity={0.6544} />
                  <stop offset="0.7966" stopColor={'#FF9026'} stopOpacity={0.2034} />
                  <stop offset="1" stopColor={'#FF7800'} stopOpacity={0} />
               </radialGradient>
               <radialGradient id="paint15_radial_121_63" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(162.345 174.598) rotate(-0.0849111) scale(1.44472 1.35659)">
                  <stop stopColor={'#FFFFD3'} />
                  <stop offset="0.3456" stopColor={'#FFCE86'} stopOpacity={0.6544} />
                  <stop offset="0.7966" stopColor={'#FF9026'} stopOpacity={0.2034} />
                  <stop offset="1" stopColor={'#FF7800'} stopOpacity={0} />
               </radialGradient>
               <radialGradient id="paint16_radial_121_63" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(164.18 171.308) rotate(-0.0849016) scale(1.44472 1.35644)">
                  <stop stopColor={'#FFFFD3'} />
                  <stop offset="0.3456" stopColor={'#FFCE86'} stopOpacity={0.6544} />
                  <stop offset="0.7966" stopColor={'#FF9026'} stopOpacity={0.2034} />
                  <stop offset="1" stopColor={'#FF7800'} stopOpacity={0} />
               </radialGradient>
               <radialGradient id="paint17_radial_121_63" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(167.763 175.883) rotate(-0.0849207) scale(1.44472 1.35675)">
                  <stop stopColor={'#FFFFD3'} />
                  <stop offset="0.3456" stopColor={'#FFCE86'} stopOpacity={0.6544} />
                  <stop offset="0.7966" stopColor={'#FF9026'} stopOpacity={0.2034} />
                  <stop offset="1" stopColor={'#FF7800'} stopOpacity={0} />
               </radialGradient>
               <radialGradient id="paint18_radial_121_63" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(184.767 183.943) rotate(-0.0849099) scale(1.44471 1.35657)">
                  <stop stopColor={'#FFFFD3'} />
                  <stop offset="0.3456" stopColor={'#FFCE86'} stopOpacity={0.6544} />
                  <stop offset="0.7966" stopColor={'#FF9026'} stopOpacity={0.2034} />
                  <stop offset="1" stopColor={'#FF7800'} stopOpacity={0} />
               </radialGradient>
               <radialGradient id="paint19_radial_121_63" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(167.763 190.486) rotate(-0.0848996) scale(1.44472 1.35641)">
                  <stop stopColor={'#FFFFD3'} />
                  <stop offset="0.3456" stopColor={'#FFCE86'} stopOpacity={0.6544} />
                  <stop offset="0.7966" stopColor={'#FF9026'} stopOpacity={0.2034} />
                  <stop offset="1" stopColor={'#FF7800'} stopOpacity={0} />
               </radialGradient>
               <radialGradient id="paint20_radial_121_63" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(190.756 173.07) rotate(-0.0849294) scale(1.44472 1.35689)">
                  <stop stopColor={'#FFFFD3'} />
                  <stop offset="0.3456" stopColor={'#FFCE86'} stopOpacity={0.6544} />
                  <stop offset="0.7966" stopColor={'#FF9026'} stopOpacity={0.2034} />
                  <stop offset="1" stopColor={'#FF7800'} stopOpacity={0} />
               </radialGradient>
               <radialGradient id="paint21_radial_121_63" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(169.808 172.371) rotate(-0.084883) scale(0.686997 0.644879)">
                  <stop stopColor={'#FFFFD3'} />
                  <stop offset="0.3456" stopColor={'#FFCE86'} stopOpacity={0.6544} />
                  <stop offset="0.7966" stopColor={'#FF9026'} stopOpacity={0.2034} />
                  <stop offset="1" stopColor={'#FF7800'} stopOpacity={0} />
               </radialGradient>
               <radialGradient id="paint22_radial_121_63" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(171.288 183.338) rotate(-0.0848759) scale(0.687003 0.644831)">
                  <stop stopColor={'#FFFFD3'} />
                  <stop offset="0.3456" stopColor={'#FFCE86'} stopOpacity={0.6544} />
                  <stop offset="0.7966" stopColor={'#FF9026'} stopOpacity={0.2034} />
                  <stop offset="1" stopColor={'#FF7800'} stopOpacity={0} />
               </radialGradient>
               <radialGradient id="paint23_radial_121_63" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.793 186.235) rotate(-0.0849009) scale(0.687007 0.645024)">
                  <stop stopColor={'#FFFFD3'} />
                  <stop offset="0.3456" stopColor={'#FFCE86'} stopOpacity={0.6544} />
                  <stop offset="0.7966" stopColor={'#FF9026'} stopOpacity={0.2034} />
                  <stop offset="1" stopColor={'#FF7800'} stopOpacity={0} />
               </radialGradient>
               <radialGradient id="paint24_radial_121_63" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(174.048 175.521) rotate(-0.0849218) scale(0.686992 0.645169)">
                  <stop stopColor={'#FFFFD3'} />
                  <stop offset="0.3456" stopColor={'#FFCE86'} stopOpacity={0.6544} />
                  <stop offset="0.7966" stopColor={'#FF9026'} stopOpacity={0.2034} />
                  <stop offset="1" stopColor={'#FF7800'} stopOpacity={0} />
               </radialGradient>
               <radialGradient id="paint25_radial_121_63" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(177.455 180.395) rotate(-0.0848971) scale(0.687003 0.644992)">
                  <stop stopColor={'#FFFFD3'} />
                  <stop offset="0.3456" stopColor={'#FFCE86'} stopOpacity={0.6544} />
                  <stop offset="0.7966" stopColor={'#FF9026'} stopOpacity={0.2034} />
                  <stop offset="1" stopColor={'#FF7800'} stopOpacity={0} />
               </radialGradient>
            </defs>
         </svg>
      </div>
   )
}