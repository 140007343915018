import React from "react";

const RustoreSvg = () => {
  return (
    <span className="svgg">
      <svg
        width="264"
        height="108"
        viewBox="0 0 164 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 10.4286C3 6.32588 5.94078 3 9.56842 3H152.432C156.059 3 159 6.32589 159 10.4286V47.5714C159 51.6741 156.059 55 152.432 55H9.56842C5.94078 55 3 51.6741 3 47.5714V10.4286Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M152.432 4H9.56842C6.60517 4 4 6.75896 4 10.4286V47.5714C4 51.241 6.60517 54 9.56842 54H152.432C155.395 54 158 51.241 158 47.5714V10.4286C158 6.75896 155.395 4 152.432 4ZM9.56842 3C5.94078 3 3 6.32588 3 10.4286V47.5714C3 51.6741 5.94078 55 9.56842 55H152.432C156.059 55 159 51.6741 159 47.5714V10.4286C159 6.32588 156.059 3 152.432 3H9.56842Z"
          fill="#A6A6A6"
        />
        <path
          d="M16.8173 44.1622C19.3329 46.6426 23.3817 46.6426 31.4793 46.6426H32.9107C41.0083 46.6426 45.0571 46.6426 47.5727 44.1622C50.0882 41.6819 50.0882 37.6897 50.0882 29.7054V28.294C50.0882 20.3097 50.0882 16.3176 47.5727 13.8372C45.0571 11.3569 41.0083 11.3569 32.9107 11.3569H31.4793C23.3817 11.3569 19.3329 11.3569 16.8173 13.8372C14.3018 16.3176 14.3018 20.3097 14.3018 28.294V29.7054C14.3018 37.6897 14.3018 41.6819 16.8173 44.1622Z"
          fill="#0077FF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M41.9297 34.81L39.5117 34.2144C39.2205 34.132 39.0149 33.8737 39.0046 33.5735L38.7028 24.7984C38.6155 23.637 37.7411 22.7132 36.8274 22.4412C36.7761 22.4259 36.7213 22.4465 36.6909 22.49C36.66 22.5343 36.672 22.5955 36.715 22.6283C36.9407 22.8006 37.5617 23.3626 37.5617 24.332L37.5599 35.8052C37.5599 36.913 36.4982 37.7255 35.4031 37.4558L32.9477 36.851C32.6755 36.7571 32.4863 36.5071 32.4764 36.2187L32.1747 27.443C32.0874 26.2816 31.213 25.3579 30.2993 25.0858C30.248 25.0706 30.1932 25.0911 30.1628 25.1346C30.132 25.1789 30.1439 25.2401 30.1869 25.273C30.4126 25.4452 31.0336 26.0072 31.0336 26.9766L31.0318 36.5401L31.0332 36.5403V38.4509C31.0332 39.5587 29.9714 40.3713 28.8763 40.1015L21.941 38.3933C20.9768 38.1558 20.3008 37.3054 20.3008 36.33L20.3008 24.8389C20.3008 23.7311 21.3625 22.9186 22.4576 23.1883L26.8276 24.2647V22.1932C26.8276 21.0854 27.8893 20.2728 28.9844 20.5426L33.3541 21.6189V19.5474C33.3541 18.4396 34.4158 17.627 35.511 17.8968L42.4463 19.605C43.4105 19.8425 44.0865 20.6929 44.0865 21.6683V33.1594C44.0865 34.2672 43.0248 35.0797 41.9297 34.81Z"
          fill="white"
        />
        <path
          d="M71.0475 43.8571H68.3127L63.9731 37.8846H61.6903V43.8571H59.3623V28.1457H64.9676C66.0525 28.1457 67.0244 28.3537 67.8833 28.7697C68.7421 29.1857 69.4051 29.7651 69.8722 30.508C70.3544 31.236 70.5955 32.0754 70.5955 33.0263C70.5955 34.2149 70.2263 35.2326 69.488 36.0794C68.7497 36.9114 67.7627 37.4611 66.5271 37.7286L71.0475 43.8571ZM61.6903 30.2629V35.7897H64.9676C65.9319 35.7897 66.708 35.5371 67.2956 35.032C67.8983 34.512 68.1997 33.8434 68.1997 33.0263C68.1997 32.2091 67.8983 31.548 67.2956 31.0429C66.708 30.5229 65.9319 30.2629 64.9676 30.2629H61.6903Z"
          fill="white"
        />
        <path
          d="M77.2248 44.08C76.396 44.08 75.6502 43.8794 74.9872 43.4783C74.3242 43.0623 73.8043 42.4977 73.4276 41.7846C73.0509 41.0566 72.8626 40.2394 72.8626 39.3331V32.3354H75.0776V39.1549C75.0776 40.0314 75.3262 40.7446 75.8234 41.2943C76.3207 41.844 76.9611 42.1189 77.7446 42.1189C78.5583 42.1189 79.2363 41.8366 79.7788 41.272C80.3212 40.7074 80.5925 40.0017 80.5925 39.1549V32.3354H82.8075V43.8571H81.2253L80.7055 42.1189C80.3288 42.7429 79.8315 43.2257 79.2137 43.5674C78.611 43.9091 77.948 44.08 77.2248 44.08Z"
          fill="white"
        />
        <path
          d="M91.0648 44.08C89.6032 44.08 88.3299 43.7086 87.245 42.9657C86.1752 42.208 85.4444 41.1829 85.0526 39.8903L87.2902 39.5337C87.7272 40.3657 88.2471 40.9823 88.8498 41.3834C89.4525 41.7697 90.1908 41.9629 91.0648 41.9629C91.9538 41.9629 92.677 41.7697 93.2346 41.3834C93.7921 40.9823 94.0708 40.44 94.0708 39.7566C94.0708 39.2514 93.9126 38.828 93.5962 38.4863C93.2948 38.1446 92.9106 37.8697 92.4435 37.6617C91.9764 37.4537 91.3661 37.2234 90.6127 36.9709C89.6032 36.644 88.7744 36.3171 88.1265 35.9903C87.4786 35.6634 86.9286 35.2029 86.4766 34.6086C86.0396 33.9994 85.8211 33.2269 85.8211 32.2909C85.8211 31.4143 86.0396 30.6491 86.4766 29.9954C86.9286 29.3269 87.5464 28.8143 88.3299 28.4577C89.1135 28.1011 89.9874 27.9229 90.9518 27.9229C92.2928 27.9229 93.4455 28.272 94.4099 28.9703C95.3742 29.6537 96.0598 30.5971 96.4667 31.8006L94.2743 32.1571C93.9729 31.4737 93.5435 30.9537 92.9859 30.5971C92.4284 30.2257 91.773 30.04 91.0196 30.04C90.2059 30.04 89.5354 30.2331 89.008 30.6194C88.4806 30.9909 88.2169 31.5034 88.2169 32.1571C88.2169 32.6474 88.3676 33.0634 88.669 33.4051C88.9854 33.7469 89.3696 34.0217 89.8217 34.2297C90.2888 34.4377 90.9066 34.6754 91.675 34.9429C92.6544 35.2697 93.4681 35.604 94.116 35.9457C94.779 36.2726 95.3365 36.7406 95.7886 37.3497C96.2406 37.9589 96.4667 38.724 96.4667 39.6451C96.4667 40.5366 96.2256 41.3166 95.7434 41.9851C95.2763 42.6537 94.6284 43.1737 93.7996 43.5451C92.9859 43.9017 92.0743 44.08 91.0648 44.08Z"
          fill="white"
        />
        <path
          d="M105.823 41.9406L105.62 43.8571H103.563C102.342 43.8571 101.416 43.56 100.783 42.9657C100.15 42.3714 99.8335 41.5171 99.8335 40.4029V34.2743H97.8219V32.3354H99.8335L100.444 28.9926H102.048V32.3354H105.62V34.2743H102.048V40.4029C102.048 41.428 102.553 41.9406 103.563 41.9406H105.823Z"
          fill="white"
        />
        <path
          d="M113.21 44.08C112.019 44.08 110.957 43.8274 110.023 43.3223C109.089 42.8171 108.358 42.1114 107.83 41.2051C107.303 40.2989 107.039 39.2663 107.039 38.1074C107.039 36.9486 107.303 35.916 107.83 35.0097C108.358 34.1034 109.089 33.3977 110.023 32.8926C110.957 32.3726 112.019 32.1126 113.21 32.1126C114.4 32.1126 115.462 32.3726 116.396 32.8926C117.331 33.3977 118.061 34.1034 118.589 35.0097C119.116 35.916 119.38 36.9486 119.38 38.1074C119.38 39.2663 119.116 40.2989 118.589 41.2051C118.061 42.1114 117.331 42.8171 116.396 43.3223C115.462 43.8274 114.4 44.08 113.21 44.08ZM113.21 42.0743C114.355 42.0743 115.281 41.7103 115.99 40.9823C116.713 40.2543 117.075 39.296 117.075 38.1074C117.075 36.9189 116.713 35.9606 115.99 35.2326C115.281 34.4897 114.355 34.1183 113.21 34.1183C112.064 34.1183 111.138 34.4897 110.43 35.2326C109.721 35.9606 109.367 36.9189 109.367 38.1074C109.367 39.2811 109.721 40.2394 110.43 40.9823C111.138 41.7103 112.064 42.0743 113.21 42.0743Z"
          fill="white"
        />
        <path
          d="M121.755 43.8571V32.3354H123.36L123.835 33.9846C124.181 33.4051 124.611 32.9891 125.123 32.7366C125.635 32.4691 126.246 32.3354 126.954 32.3354H127.496L127.677 34.4303H126.411C125.598 34.4303 124.987 34.6606 124.581 35.1211C124.174 35.5817 123.97 36.2057 123.97 36.9931V43.8571H121.755Z"
          fill="white"
        />
        <path
          d="M140.34 38.0183C140.34 38.3154 140.333 38.5457 140.318 38.7091H130.666C130.787 39.7343 131.194 40.5514 131.887 41.1606C132.58 41.7697 133.462 42.0743 134.531 42.0743C135.27 42.0743 135.925 41.9331 136.498 41.6509C137.085 41.3537 137.53 40.9674 137.831 40.492L139.911 40.8263C139.459 41.8663 138.728 42.6686 137.718 43.2331C136.724 43.7977 135.616 44.08 134.396 44.08C133.236 44.08 132.196 43.8274 131.277 43.3223C130.358 42.8023 129.642 42.0891 129.13 41.1829C128.617 40.2766 128.361 39.2514 128.361 38.1074C128.361 36.9486 128.617 35.916 129.13 35.0097C129.642 34.1034 130.35 33.3977 131.254 32.8926C132.158 32.3726 133.19 32.1126 134.351 32.1126C135.496 32.1126 136.52 32.3726 137.424 32.8926C138.344 33.3977 139.059 34.1034 139.572 35.0097C140.084 35.9011 140.34 36.904 140.34 38.0183ZM134.351 34.0514C133.462 34.0514 132.693 34.3114 132.045 34.8314C131.412 35.3514 130.99 36.0497 130.779 36.9263H137.922C137.726 36.0646 137.304 35.3737 136.656 34.8537C136.023 34.3189 135.255 34.0514 134.351 34.0514Z"
          fill="white"
        />
        <path
          d="M62.6559 22.1199C61.8639 22.1199 61.1519 21.9359 60.5199 21.5679C59.8879 21.1919 59.3919 20.6719 59.0319 20.0079C58.6799 19.3439 58.5039 18.5959 58.5039 17.7639C58.5039 16.9319 58.6799 16.1879 59.0319 15.5319C59.3919 14.8679 59.8839 14.3519 60.5079 13.9839C61.1399 13.6079 61.8519 13.4199 62.6439 13.4199C63.4999 13.4199 64.2599 13.6359 64.9239 14.0679C65.5959 14.4919 66.0759 15.0679 66.3639 15.7959L65.1399 15.9879C64.9159 15.5399 64.5839 15.1919 64.1439 14.9439C63.7119 14.6879 63.2239 14.5599 62.6799 14.5599C62.1199 14.5599 61.6199 14.6959 61.1799 14.9679C60.7479 15.2399 60.4079 15.6199 60.1599 16.1079C59.9199 16.5879 59.7999 17.1399 59.7999 17.7639C59.7999 18.3959 59.9199 18.9559 60.1599 19.4439C60.4079 19.9239 60.7519 20.2999 61.1919 20.5719C61.6319 20.8439 62.1359 20.9799 62.7039 20.9799C63.2639 20.9799 63.7599 20.8519 64.1919 20.5959C64.6319 20.3319 64.9679 19.9639 65.1999 19.4919L66.4239 19.6839C66.1199 20.4279 65.6279 21.0199 64.9479 21.4599C64.2679 21.8999 63.5039 22.1199 62.6559 22.1199Z"
          fill="white"
        />
        <path
          d="M72.6941 21.9999H71.1461L68.8541 19.4319H68.4821V21.9999H67.3061V15.7959H68.4821V18.3039H68.8421L71.0381 15.7959H72.4541L69.7901 18.7959L72.6941 21.9999Z"
          fill="white"
        />
        <path
          d="M75.4907 15.6759C76.2587 15.6759 76.8627 15.9279 77.3027 16.4319C77.7507 16.9279 77.9747 17.6239 77.9747 18.5199V21.9999H77.1347L76.8587 21.0279C76.6587 21.3399 76.3907 21.5999 76.0547 21.8079C75.7187 22.0159 75.3147 22.1199 74.8427 22.1199C74.4267 22.1199 74.0547 22.0359 73.7267 21.8679C73.3987 21.6999 73.1427 21.4679 72.9587 21.1719C72.7747 20.8679 72.6827 20.5279 72.6827 20.1519C72.6827 19.5679 72.9147 19.0999 73.3787 18.7479C73.8427 18.3959 74.5147 18.2199 75.3947 18.2199H76.7987C76.7667 17.7319 76.6307 17.3559 76.3907 17.0919C76.1507 16.8199 75.8307 16.6839 75.4307 16.6839C75.1267 16.6839 74.8587 16.7559 74.6267 16.8999C74.3947 17.0439 74.2187 17.2319 74.0987 17.4639L73.0067 17.2839C73.1667 16.7879 73.4747 16.3959 73.9307 16.1079C74.3867 15.8199 74.9067 15.6759 75.4907 15.6759ZM75.0947 21.1239C75.5987 21.1239 76.0067 20.9599 76.3187 20.6319C76.6387 20.2959 76.7987 19.8359 76.7987 19.2519V19.1799H75.4307C74.3907 19.1799 73.8707 19.4919 73.8707 20.1159C73.8707 20.4199 73.9827 20.6639 74.2067 20.8479C74.4307 21.0319 74.7267 21.1239 75.0947 21.1239Z"
          fill="white"
        />
        <path
          d="M82.5831 19.7439C82.3911 19.8319 82.1631 19.8999 81.8991 19.9479C81.6351 19.9959 81.3751 20.0199 81.1191 20.0199C80.3751 20.0199 79.8031 19.8119 79.4031 19.3959C79.0031 18.9799 78.8031 18.3919 78.8031 17.6319V15.7959H79.9791V17.6319C79.9791 18.5199 80.4071 18.9639 81.2631 18.9639C81.7831 18.9639 82.2231 18.8719 82.5831 18.6879V15.7959H83.7591V21.9999H82.5831V19.7439Z"
          fill="white"
        />
        <path
          d="M87.5849 15.6759C88.3529 15.6759 88.9569 15.9279 89.3969 16.4319C89.8449 16.9279 90.0689 17.6239 90.0689 18.5199V21.9999H89.2289L88.9529 21.0279C88.7529 21.3399 88.4849 21.5999 88.1489 21.8079C87.8129 22.0159 87.4089 22.1199 86.9369 22.1199C86.5209 22.1199 86.1489 22.0359 85.8209 21.8679C85.4929 21.6999 85.2369 21.4679 85.0529 21.1719C84.8689 20.8679 84.7769 20.5279 84.7769 20.1519C84.7769 19.5679 85.0089 19.0999 85.4729 18.7479C85.9369 18.3959 86.6089 18.2199 87.4889 18.2199H88.8929C88.8609 17.7319 88.7249 17.3559 88.4849 17.0919C88.2449 16.8199 87.9249 16.6839 87.5249 16.6839C87.2209 16.6839 86.9529 16.7559 86.7209 16.8999C86.4889 17.0439 86.3129 17.2319 86.1929 17.4639L85.1009 17.2839C85.2609 16.7879 85.5689 16.3959 86.0249 16.1079C86.4809 15.8199 87.0009 15.6759 87.5849 15.6759ZM87.1889 21.1239C87.6929 21.1239 88.1009 20.9599 88.4129 20.6319C88.7329 20.2959 88.8929 19.8359 88.8929 19.2519V19.1799H87.5249C86.4849 19.1799 85.9649 19.4919 85.9649 20.1159C85.9649 20.4199 86.0769 20.6639 86.3009 20.8479C86.5249 21.0319 86.8209 21.1239 87.1889 21.1239Z"
          fill="white"
        />
        <path
          d="M93.1173 21.9999H91.3773V15.7959H92.5173V20.6799H92.6973L95.2053 15.7959H96.9453V21.9999H95.8053V17.1279H95.6253L93.1173 21.9999ZM94.1733 14.9919C93.6533 14.9919 93.2173 14.8559 92.8653 14.5839C92.5213 14.3039 92.3173 13.8879 92.2533 13.3359H93.2133C93.2533 13.5919 93.3533 13.7879 93.5133 13.9239C93.6813 14.0519 93.9013 14.1159 94.1733 14.1159C94.4453 14.1159 94.6613 14.0519 94.8213 13.9239C94.9893 13.7879 95.0893 13.5919 95.1213 13.3359H96.0933C96.0213 13.8879 95.8093 14.3039 95.4573 14.5839C95.1133 14.8559 94.6853 14.9919 94.1733 14.9919Z"
          fill="white"
        />
        <path
          d="M101.033 21.9999H99.8572V16.8759H97.7092V15.7959H103.181V16.8759H101.033V21.9999Z"
          fill="white"
        />
        <path
          d="M109.729 18.8559C109.729 19.0159 109.725 19.1399 109.717 19.2279H104.593C104.657 19.7799 104.873 20.2199 105.241 20.5479C105.609 20.8759 106.077 21.0399 106.645 21.0399C107.037 21.0399 107.385 20.9639 107.689 20.8119C108.001 20.6519 108.237 20.4439 108.397 20.1879L109.501 20.3679C109.261 20.9279 108.873 21.3599 108.337 21.6639C107.809 21.9679 107.221 22.1199 106.573 22.1199C105.957 22.1199 105.405 21.9839 104.917 21.7119C104.429 21.4319 104.049 21.0479 103.777 20.5599C103.505 20.0719 103.369 19.5199 103.369 18.9039C103.369 18.2799 103.505 17.7239 103.777 17.2359C104.049 16.7479 104.425 16.3679 104.905 16.0959C105.385 15.8159 105.933 15.6759 106.549 15.6759C107.157 15.6759 107.701 15.8159 108.181 16.0959C108.669 16.3679 109.049 16.7479 109.321 17.2359C109.593 17.7159 109.729 18.2559 109.729 18.8559ZM106.549 16.7199C106.077 16.7199 105.669 16.8599 105.325 17.1399C104.989 17.4199 104.765 17.7959 104.653 18.2679H108.445C108.341 17.8039 108.117 17.4319 107.773 17.1519C107.437 16.8639 107.029 16.7199 106.549 16.7199Z"
          fill="white"
        />
        <path
          d="M115.009 21.9999H113.269V15.7959H114.409V20.6799H114.589L117.097 15.7959H118.837V21.9999H117.697V17.1279H117.517L115.009 21.9999Z"
          fill="white"
        />
        <path
          d="M123.597 18.8319C123.925 18.9279 124.197 19.1039 124.413 19.3599C124.629 19.6079 124.737 19.9159 124.737 20.2839C124.737 20.6279 124.637 20.9399 124.437 21.2199C124.237 21.4919 123.949 21.7119 123.573 21.8799C123.205 22.0399 122.777 22.1199 122.289 22.1199C121.633 22.1199 121.069 21.9679 120.597 21.6639C120.125 21.3519 119.813 20.9359 119.661 20.4159L120.753 20.2359C121.017 20.8119 121.525 21.0999 122.277 21.0999C122.693 21.0999 123.009 21.0239 123.225 20.8719C123.449 20.7119 123.561 20.4919 123.561 20.2119C123.561 19.9639 123.457 19.7639 123.249 19.6119C123.049 19.4599 122.725 19.3839 122.277 19.3839H121.461V18.3519H122.277C122.661 18.3519 122.953 18.2799 123.153 18.1359C123.361 17.9919 123.465 17.7999 123.465 17.5599C123.465 17.3039 123.353 17.0959 123.129 16.9359C122.913 16.7759 122.645 16.6959 122.325 16.6959C122.013 16.6959 121.733 16.7719 121.485 16.9239C121.237 17.0759 121.049 17.2839 120.921 17.5479L119.829 17.3559C120.013 16.8199 120.329 16.4079 120.777 16.1199C121.233 15.8239 121.761 15.6759 122.361 15.6759C122.793 15.6759 123.181 15.7519 123.525 15.9039C123.877 16.0559 124.149 16.2679 124.341 16.5399C124.541 16.8119 124.641 17.1159 124.641 17.4519C124.641 17.7959 124.545 18.0879 124.353 18.3279C124.161 18.5679 123.909 18.7359 123.597 18.8319Z"
          fill="white"
        />
      </svg>
    </span>
  );
};

export default RustoreSvg;
